import produce from 'immer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware } from 'redux';

const initialState = {
  navbarHeight: 84, 
  windowWidth: typeof window !== `undefined` ? window.innerWidth : null,
};

const globalReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case 'SET_NAVBAR_HEIGHT': {
      draft.navbarHeight = action.payload.height;
      break;
    }
    case 'SET_WINDOW_WIDTH': {
      draft.windowWidth = action.payload.width;
      break;
    }
    default:
      return state;
  }
});

const rootReducer = combineReducers({
  global: globalReducer,
});

export default (preloadedState = {}) =>
  createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware()));
