// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-index-js": () => import("./../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-index-2-js": () => import("./../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-category-page-index-js": () => import("./../src/templates/category-page/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-category-post-index-js": () => import("./../src/templates/category-post/index.js" /* webpackChunkName: "component---src-templates-category-post-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../src/templates/home-page/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-tag-page-index-js": () => import("./../src/templates/tag-page/index.js" /* webpackChunkName: "component---src-templates-tag-page-index-js" */)
}

